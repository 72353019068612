<template>
  <v-tooltip
    :top="computedToolTipTop"
    :bottom="computedTooTipBottom"
    :left="computedToolTipLeft"
    :right="computedToolTipRight"
    :disabled="!hasSlot('toolTip')"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :fab="fab"
        :small="small"
        :large="large"
        :color="computedButtonColorClass"
        :outlined="computedButtonOutlined"
        :text="buttonRecommendedTextStyle"
        :rounded="computedButtonRounded"
        :elevation="buttonElevation"
        :class="buttonRecommendedClass(spaceDirection)"
        :to="to"
        :disabled="disabled"
        :loading="loading"
        :icon="isIcon"
        @click="onClick"
      >
        <!-- button icon -->
        <slot name="icon">
          <v-icon v-show="hasSlot('icon-name')">
            <slot name="icon-name"></slot>
          </v-icon>
        </slot>

        <!-- button text using default slot -->
        <slot name="default" v-if="visibleText"></slot>
      </v-btn>
    </template>

    <!-- button Tooltip -->
    <span>
      <slot name="toolTip"></slot>
    </span>
  </v-tooltip>
</template>

<script>
import { baseButtonMixin } from "@/mixins/shared/base/button/baseButtonMixin";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "BaseTooltipButton",
  mixins: [baseButtonMixin, slottableMixin],
  props: {
    to: {
      type: Object,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    toolTipTop: {
      type: Boolean,
      default: undefined
    },
    toolTipBottom: {
      type: Boolean,
      default: undefined
    },
    toolTipLeft: {
      type: Boolean,
      default: undefined
    },
    toolTipRight: {
      type: Boolean,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    isIcon: {
      type: Boolean,
      default: false
    },
    spaceDirection: {
      type: String,
      default: undefined // spacingDirection.left
    }
  },
  computed: {
    computedToolTipTop() {
      return this.toolTipTop === undefined ? false : this.toolTipTop;
    },
    computedTooTipBottom() {
      return this.toolTipBottom === undefined ? true : this.toolTipBottom;
    },
    computedToolTipLeft() {
      return this.toolTipLeft === undefined ? false : this.toolTipLeft;
    },
    computedToolTipRight() {
      return this.toolTipRight === undefined ? false : this.toolTipRight;
    }
  },
  methods: {
    onClick(e) {
      this.$emit(this.eventButtonClick, e);
    }
  }
};
</script>
